<template>
    <div class="absolute z-10 w-full h-full pointer-events-none">
        <div class="z-20 absolute right-4 top-4 rounded-full bg-orange h-12 w-12 flex items-center justify-center cursor-pointer pointer-events-auto" @click="toggleInputs">
            <MenuIcon class="h-8 stroke-beige" v-if="!opened"/>
            <XIcon class="h-8 stroke-beige" v-else/>
        </div>
        <div class="absolute top-0 left-0 bottom-0 right-0 px-8 py-12 md:py-16 flex justify-center pointer-events-auto" v-if="opened">
            <div class="max-w-lg w-full">
                <div class="space-y-2 md:space-y-4">
                    <div>
                        <label class="block text-orange font-bold text-lg">Ime otroka</label>
                        <input type="text" v-model="inputData.name" class="w-full px-2 h-9 text-sm border-orange rounded-lg bg-white border">
                    </div>
                    <div>
                        <label class="block text-orange font-bold text-lg">Starost otroka</label>
                        <input type="number" v-model="inputData.age" min="4" max="18" class="w-full px-2 h-9 text-sm border-orange rounded-lg bg-white border">
                    </div>
                    <div class="flex flex-wrap md:space-x-4 space-y-2 md:space-y-0 md:flex-nowrap">
                        <div class="w-full md:w-1/2">
                            <label class="block text-orange font-bold text-lg">Datum zabave</label>  
                            <input class="flex-none md:flex-1 w-full px-2 text-sm border-orange rounded-lg bg-white border appearance-none h-9" v-model="inputData.date" type="date">
                        </div>
                        <div class="w-full md:w-1/4">
                            <label class="block text-orange font-bold text-lg">Od</label>  
                            <input type="time" v-model="inputData.time" class="flex-none md:flex-1 w-full px-2 text-sm border-orange rounded-lg bg-white border appearance-none h-9">
                        </div>
                        <div class="w-full md:w-1/4">
                            <label class="block text-orange font-bold text-lg">Do</label>  
                            <input type="time" v-model="inputData.until" class="flex-none md:flex-1 w-full px-2 text-sm border-orange rounded-lg bg-white border appearance-none h-9">
                        </div>
                    </div>
                    <div>
                        <label class="block text-orange font-bold text-lg">Pripis <span class="text-sm">(največ 3 vrste)</span></label>
                        <textarea autocapitalize="off" v-model="inputData.ps" rows="3" class="w-full p-2 text-sm border-orange rounded-lg bg-white border"></textarea>
                    </div>
                    <div class="flex items-center">
                        <button @click="generate" class="p-2 text-sm border-orange rounded-lg bg-white border flex justify-center items-center">
                            <svg v-if="rendering" class="animate-spin mr-3 h-5 w-5 text-orange" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span>Naredi vabilo</span>
                        </button>
                        <span v-if="error" class="text-sm pl-3">Napaka: {{error}}</span>
                    </div>
                </div>
            </div>
            <div class="absolute -z-10 top-0 left-0 bottom-0 right-0 bg-beige opacity-90 backdrop-blur-xl"></div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, toRaw, watch } from 'vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
import useFirebase from '@/composables/useFirebase'
import useRender from '@/composables/useRender'

export default {
    components: {
        MenuIcon,
        XIcon
    },
    setup() {

        const inputData = reactive({
            age: null,
            name: null,
            date: null,
            time: null,
            ps: null,
            psArray: null
        })

        if (process.env.NODE_ENV == 'development') {
            inputData.age = 4
            inputData.name = 'Matic'
            inputData.date = '2024-01-01'
            inputData.time = '16:00'
            inputData.until = '18:00'
            inputData.ps = 'Udeležbo potrdite po telefonu ...'
        } else {
            inputData.date = '2024-01-01'
            inputData.time = '16:00'
            inputData.until = '18:00'
        }

        const opened = ref(false)
        const toggleInputs = function () {
            opened.value = !opened.value
        }

        const { rendering } = useRender()
        const { callRender } = useFirebase()
        const error = ref(null)
        const generate = async () => {
            error.value = null
            inputData.psArray = inputData.ps.split('\n')
            rendering.value = true
            try {
                await callRender(toRaw(inputData))
            } catch (e) {
                error.value = e.message
            }
            inputData.psArray = null
        }
        watch(rendering, () => {
            if (opened.value == true && rendering.value == false) {
                opened.value = false
            }
        })

        return {
            inputData,
            opened,
            toggleInputs,
            generate,
            rendering,
            error
        }

    }

}

</script>

<style lang="postcss">

    :root {
        /* --el-fill-color-blank: var(--beige); */
        --el-fill-color-blank: white; 
        --el-border-color: var(--orange);
    }
    .el-button {
        background-color: var(--el-button-bg-color) !important;
    }

</style>