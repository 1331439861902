import { ref } from 'vue'
import { getApp } from "firebase/app";
import { getStorage, ref as fref, getDownloadURL } from "firebase/storage"
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions"
import { initializeApp } from "firebase/app"
import firebaseConfig from '/firebaseConfig.js'

const app = initializeApp(firebaseConfig);
const storage = getStorage();
const functions = getFunctions(app);
if (process.env.VUE_APP_USE_FUNCTION_EMULATOR == 'true') {
    connectFunctionsEmulator(functions, "localhost", 5001);
}
const render = httpsCallable(functions, 'render-render');

const pdf = ref(null)

export default function useFunctions() {
    
    const callRender = payload => {
        return render(payload).then(async (result) => {
            const response = result.data;
            const url = await getDownloadURL(fref(storage, response.pdfUrl))
            pdf.value = url
        });
    }
    
    return {
        pdf,
        callRender
    }

}