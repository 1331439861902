<template>

    <div class="h-screen w-screen bg-gray-700 antialiased text-gray-800 relative flex items-center justify-center touch-pan-y">

        <div :style="frameStyle" class="relative border border-gray-500">
            <Inputs></Inputs>
            <Preview></Preview>
        </div>
        
    </div>

</template>

<script>
import { ref, computed } from 'vue'

import Inputs from '@/components/Inputs.vue'
import Preview from '@/components/Preview.vue'

export default {
    components: {
        Inputs,
        Preview
    },
    setup() {
        
        const documentHeight = ref(595)
        const documentWidth = ref(420)
        const documentRatio = ref(documentWidth.value / documentHeight.value)
        const frameStyle = computed(() => {
            return {
                width: `${documentWidth.value}px`,
                height: `${documentHeight.value}px`
            }
        })
        const MAX_HEIGHT = 1000
        const MAX_WIDTH = 500
        const PADDING = 20
        const onResize = () => {
            const ratio = window.innerWidth / window.innerHeight
            if (ratio > documentRatio.value) {
                documentHeight.value = Math.min(MAX_HEIGHT, window.innerHeight - 2 * PADDING)
                documentWidth.value = documentHeight.value * documentRatio.value
            } else {
                documentWidth.value = Math.min(MAX_WIDTH, window.innerWidth - 2 * PADDING)
                documentHeight.value = documentWidth.value / documentRatio.value
            }

        }
        window.addEventListener('resize', onResize)
        onResize();

        return {
            frameStyle,
        }

    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
