const pdfjsLib = require("pdfjs-dist/webpack");
import { ref } from 'vue'

const rendering  = ref(false)
const pdfData = ref(null)

export default function useRender() {
    
    const renderPdf = (path, width) => {
        rendering.value = true
        return pdfjsLib.getDocument(path).promise.then(function (pdfDocument) {
            pdfDocument.saveDocument().then(data => {
                pdfData.value = data
            })

            return pdfDocument.getPage(1).then(function (pdfPage) {

                const scale = width / pdfPage.getViewport({ scale: 1.0 }).width
                const viewport = pdfPage.getViewport({ scale });
                const canvas = document.getElementById('canvas');
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                const ctx = canvas.getContext('2d');
                const renderTask = pdfPage.render({
                    canvasContext: ctx,
                    viewport,
                });
                return renderTask.promise.then(() => {
                    rendering.value = false
                });
            });
        })
        .catch(function (reason) {
            console.error("Error: " + reason);
        });
    }
    
    return {
        renderPdf,
        rendering,
        pdfData
    }

}