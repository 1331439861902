<template>
    <div class="w-full h-full relative" ref="preview">
        <div class="absolute left-4 top-4 h-12 w-12 rounded-full bg-orange flex items-center justify-center hover:bg-orange cursor-pointer" v-if="showDownload" @click="download">
            <CloudDownloadIcon class="h-8 stroke-beige"/>
        </div>
        <canvas id="canvas" class="w-full h-full"/>
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import splash from '@/assets/zumma-invite-splash.pdf'
import useFirebase from '@/composables/useFirebase'
import useRender from '@/composables/useRender'
import { CloudDownloadIcon } from '@heroicons/vue/outline'

export default {
    components: {
        CloudDownloadIcon
    },
    setup() {

        // element reference
        const preview = ref(null)
        const showDownload = ref(false)

        const { renderPdf, pdfData } = useRender();

        const onRenderComplete = () => {
            showDownload.value = true
        }

        const download = () => {
            const blob = new Blob([pdfData.value], {
                type: 'application/pdf'
            })
            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(blob)
            a.download = `zummakids-vabilo.pdf`
            document.body.appendChild(a)
            a.style.display = 'none'
            a.click()
            a.remove()
        }

        const { pdf } = useFirebase()
        watchEffect(async () => {
            if (preview.value == null) {
                return
            }
            if (pdf.value == null) {
                await renderPdf(splash, preview.value.clientWidth)
            } else {
                await renderPdf(pdf.value, preview.value.clientWidth)
                onRenderComplete()                
            }

        })

        return {
            preview,
            showDownload,
            download
        }

    },
}
</script>
